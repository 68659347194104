import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginForm from '../pages/LoginForm.vue'
import Home from '../pages/Home.vue'

import SpeciesIndex from '../pages/SpeciesIndex.vue'
import SpeciesUpload from '../pages/SpeciesUpload.vue'
import SpeciesDetail from '../pages/SpeciesDetail.vue'
import PredictionIndex from '../pages/PredictionIndex.vue'
import PredictionForm from '../pages/PredictionForm.vue'
import TrainingIndex from '../pages/TrainingIndex.vue'
import TrainingUpload from '../pages/TrainingUpload.vue'
import TrainingDetail from '../pages/TrainingDetail.vue'
import UserIndex from '../pages/UserIndex.vue'
import UserInput from '../pages/UserInput.vue'
import DeviceIndex from '../pages/DeviceIndex.vue'
import VesselIndex from '../pages/VesselIndex.vue'
import VesselForm from '../pages/VesselForm.vue'
import SettingPage from '../pages/SettingPage.vue'
import PageNotFound from '../pages/PageNotFound.vue'
import GaleryPage from '../pages/ImageGalery.vue'
import OnboardReport from '../pages/PredictionReport.vue'
import FishingGroundReport from '../pages/ReportByFishingGround.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LoginForm',
        component: LoginForm
    }, {
        path: '/home',
        name: "home",
        component: Home,
        children: [
            {
                path: '/species',
                name: 'speciesIndex',
                component: SpeciesIndex
            },
            {
                path: '/species/upload',
                name: 'speciesUpload',
                component: SpeciesUpload
            },
            {
                path: '/species/detail',
                name: 'speciesDetail',
                component: SpeciesDetail
            },
            {
                path: '/training',
                name: 'trainingIndex',
                component: TrainingIndex
            },
            {
                path: '/training/upload',
                name: 'trainingUpload',
                component: TrainingUpload
            },
            {
                path: '/training/detail',
                name: 'trainingDetail',
                component: TrainingDetail
            },
            {
                path: '/prediction',
                name: 'predictionIndex',
                component: PredictionIndex
            },
            {
                path: '/prediction/form',
                name: 'predictionForm',
                component: PredictionForm
            },
            {
                path: '/user',
                name: 'userIndex',
                component: UserIndex
            },
            {
                path: '/user/new',
                name: 'userInput',
                component: UserInput
            },
            {
                path: '/device',
                name: 'deviceIndex',
                component: DeviceIndex
            },
            {
                path: '/vessel',
                name: 'vesselIndex',
                component: VesselIndex
            },
            {
                path: '/vessel/new',
                name: 'vesselForm',
                component: VesselForm
            },
            {
                path: '/setting',
                name: 'SettingPage',
                component: SettingPage
            },
            {
                path: '/galery',
                name: 'GaleryPage',
                component: GaleryPage
            },
            {
                path: '/onboard/report',
                name: 'OnboardReport',
                component: OnboardReport
            },
            {
                path: '/report/fishing-ground',
                name: 'FishingGroundReport',
                component: FishingGroundReport
            },
        ]
    },
    {
        path: "*",
        name: "not-found",
        icon: "mdi-home",
        component: PageNotFound,
      },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
<template>
  <v-app>
    <v-app-bar app color="primary" class="gradient elevation-1">
      <div class="d-flex align-center">
        <v-toolbar-title class="title" v-if="$vuetify.breakpoint.smAndDown">IFIP</v-toolbar-title>
        <v-toolbar-title class="title" v-else>Integrated Fisheries Identification Platform</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>

      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-menu top offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on">
              <v-icon> mdi-menu </v-icon>
            </v-btn>
          </template>

          <mini-menu :routes="routes" :linkTo="linkTo" :dotMenu="dotMenu" :menuOnClick="menuOnClick"></mini-menu>
        </v-menu>
      </div>
      <div v-else>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-menu</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in dotMenu" :key="i" @click="menuOnClick(item.action)">

              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>


    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" :mini-variant.sync="mini" permanent v-if="!$vuetify.breakpoint.smAndDown"
      class="gradient-salt">
      <v-list-item class="px-2">
        <v-list-item-avatar v-if="mini">
          <v-img alt="My Image" class="" contain :src="require('@/assets/IntelionLogo-LandscapeColor.png')"
            transition="scale-transition" width="40" />
        </v-list-item-avatar>

        <v-list-item-title><v-img alt="My Image" class="shrink ml-8 mt-2" contain
            :src="require('@/assets/IntelionLogo-LandscapeColor.png')" transition="scale-transition"
            width="150" /></v-list-item-title>

        <v-btn icon @click.stop="mini = !mini" class="minimize-icon">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list shaped>
        <v-list-item link @click="linkToGalery" disabled>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
        <v-list-group :value=" false" no-action v-for="(menu, i) in routes" :key="i">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.name }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(submenu, i) in menu.children" :key="i" link @click="linkTo(submenu)"
            :disabled="submenu.disable">
            <v-list-item-icon>
              <v-icon>{{ submenu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ submenu.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>



      </v-list>
    </v-navigation-drawer>




    <!-- <v-main>
      <UploadFile />
    </v-main> -->
    <v-main class="gradient-salt">
      <router-view refs="router-home"></router-view>
    </v-main>

  </v-app>
</template>

<style>
  .v-application .title {
    font-size: 1rem !important;
    font-weight: 1000;
    letter-spacing: 0.1em !important;
    color: white;
  }

  .v-app-bar-nav-icon {
    color: white;
  }

  .minimize-icon {
    margin-top: 0.9rem;
  }
</style>

<script>
  import miniMenu from "@/components/MiniMenu.vue";
  import menuList from "../etc/menuList";
  export default {
    name: 'App',
    components: {
      miniMenu
    },
    data: () => ({
      //
      clipped: true,
      drawer: true,
      routes: [],
      items: [
        { title: 'Species', icon: 'mdi-fish', path: 'speciesIndex' },
        { title: 'Data Training', icon: 'mdi-camera-party-mode', path: 'trainingIndex' },
        { title: 'Capture', icon: 'mdi-mace', path: 'predictionIndex' },
        { title: 'Devices', icon: 'mdi-cellphone-settings', path: 'deviceIndex' },
        { title: 'Vessel', icon: 'mdi-ferry', path: 'vesselIndex' },
        { title: 'Users', icon: 'mdi-account', path: 'userIndex' },
      ],
      dotMenu: [
        { title: 'Setting', action: "setting", icon: "mdi-cog-outline" },
        { title: 'Logout', action: "logout", icon: "mdi-logout" },
      ],
      mini: false,
      name: localStorage.name,
      notifNum: "30"
    }),
    created() {
      this.routes = menuList;
    },
    methods: {
      logout() {
        this.$router.push('/')
      },
      linkToGalery() {
        this.$router.push('/galery')
      },
      linkTo(v) {
        this.$router.push(v.path);
      },
      menuOnClick(action) {
        console.log("werwer")
        if (action == "logout") {
          this.logout()
        }
        if (action == "setting") {
          this.$router.push({ name: "SettingPage" })
        }
      }
    }
  };
</script>
<template>
  <v-container fluid class="pa-7">
    <overlay :loading="loading"></overlay>
    <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
    <my-table :data="data" :headers="headers" :editable="editable" :formatPercentage="formatPercentage"
      :showDetail="showDetail" :showDetailFunction="showDetailFunction"></my-table>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600">
      <template>
        <v-card>
          <v-toolbar class="gradient" dark>{{ predictionDetail.species }}</v-toolbar>
          <v-img class="white--text align-start" contain
            :src="baseImageUrl + '/images/prediction/'+ this.predictionDetail.image"></v-img>
          <v-card-text class="ma-4">
            <div class="text-subtitle-1">Size: {{ predictionDetail.size }}</div>
            <div class="text-subtitle-1">Location: {{ predictionDetail.location }}</div>
            <div class="text-subtitle-1">Precision: {{ formatPercentage(predictionDetail.precision) }}</div>
            <div class="d-flex justify-end" v-if="predictionDetail.status === 'Unidentified'">
              <my-button :color="'primary'" :onClick="onClickIdentify" :tooltip="'Identify Prediction'"
                :label="'Identify'"></my-button>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="confirmDialogShow" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Identify prediction?
        </v-card-title>
        <v-card-text>

          <v-container>
            <v-row>
              This action updates the prediction status to "Identified" and copies the image to the 'Train'
              folder.
            </v-row>
            <v-row class="mt-8">
              <v-autocomplete outlined dense label="Species" auto-select-first clearable v-model="species"
                :items="speciesData" item-text="species" return-object></v-autocomplete>
            </v-row>
            <v-row>
              <v-text-field outlined dense v-model="size" label="Size" hide-details="auto"></v-text-field>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirmDialogShow = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="updateStatus">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import MyPageHeader from "@/components/PageHeaders.vue";
  import Overlay from "@/components/Overlay.vue";
  import MyTable from "@/components/DataTable.vue";
  import MyButton from "@/components/Button.vue";
  export default {
    components: {
      MyPageHeader,
      Overlay,
      MyTable,
      MyButton,
    },
    data() {
      return {
        title: "Onboard - Daftar Data",
        description: "Keseluruhan Data Onboard yang Tersimpan",
        breadcrumbs: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Onboard",
            disabled: true,
            href: "/prediction",
          },
        ],
        loading: false,
        snackColor: "success",
        snackMessage: "",
        showSnack: false,
        editable: false,
        predictionDetail: {},
        showDetail: true,
        dialog: false,
        confirmDialogShow: false,
        baseImageUrl: process.env.VUE_APP_HOST,
        headers: [
          {
            text: 'Device',
            align: 'start',
            sortable: false,
            value: 'device.buildModel',
          },
          { text: 'Kapal', value: 'vessel.name' },
          { text: 'Species', value: 'species' },
          { text: 'Precision', value: 'precision' },
          { text: 'Location', value: 'location' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        species: null,
        size: null,
        data: [],
        speciesData: [],
      }
    },
    methods: {
      async onClickIdentify() {
        this.confirmDialogShow = true;
      },
      async updateStatus() {
        this.loading = true;

        let detail = this.predictionDetail;
        let status = 'Identified';
        let species = this.species.species;
        let size = this.size;

        await this.$http.put(`/api/v1/prediction/update/status`, {
          status: status,
          species: species,
          size: size,
        }, {
          params: {
            prediction_id: detail.id,
          },
        })
          .then(() => {
            // handle success
            this.loading = false;
            this.confirmDialogShow = false;
            this.dialog = false;
            this.loadData();
          })
          .catch((error) => {
            this.loading = false;
            this.confirmDialogShow = false;
            this.dialog = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
      onClickNewPrediction() {
        this.$router.push({ name: 'predictionForm' })
      },
      loadSpecies() {
        this.loading = true;
        this.$http.get('api/v1/training/image/list')
          .then((response) => {
            // handle success
            this.speciesData = response.data.data
            this.loading = false;
          })
          .catch((error) => {
            // handle error
            this.loading = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
      loadData() {
        this.loading = true;
        this.$http.get('/api/v1/prediction/list')
          .then((response) => {
            // handle success
            this.data = response.data.data
            this.loading = false;
          })
          .catch((error) => {
            // handle error
            this.loading = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
      formatPercentage(value) {
        if (value != null) {
          return (value).toFixed(2) + '%';
        }
        return '';
      },
      showDetailFunction(item) {
        this.predictionDetail = item;
        this.dialog = true;
      },
    },
    created() {
      this.loadData();
      this.loadSpecies();
    }
  }
</script>
<template>
    <div style="width: 100%">
        <v-data-table hide-default-footer :headers="headers" :items="data" class="elevation-1"
            :items-per-page="pagination.totalItems" :options.sync="pagination">
            <!-- <template v-for="(header, index) in headers" :key="index" v-slot:[`item.${header.value}`]="{ item }">
                <td v-if="header.value === 'status'" :key="header.value">
                    <v-chip v-bind="getChipProps(item.status)">{{ item.status }}</v-chip>
                </td>
                <td v-else :key="header.value">{{ item[header.value] }}</td>
            </template> -->
            <template v-slot:item.precision="{ item }">
                {{ formatPercentage(item.precision) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </template>
                    <v-list>
                        <v-list-item v-if="editable" @click="onClickEditItem(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.status === 'Archive'" @click="onClickArchiveItem(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-archive-cancel
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Unarchive</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.status === 'Unarchive'" @click="onClickArchiveItem(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-archive
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Archive</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.status === 'Unidentify'" @click="onClickIdentifyItem(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-archive
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Identify</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="showDetail" @click="showDetailFunction(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-image-text
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Detail</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <div class="text-center ma-4">
            <v-pagination v-model="currentPageLocal" :length="totalPageCount"
                :total-visible="pagination.totalItems"></v-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "myDataTable",
        props: {
            data: {
                type: Array,
                required: false,
            },
            headers: {
                type: Array,
                required: true,
            },
            editable: Boolean,
            showDetail: Boolean,
            loading: Boolean,
            onClickEditItem: Function,
            onClickArchiveItem: Function,
            showDetailFunction: Function,
            formatPercentage: Function,
            currentPage: {
                type: Number,
                default: 1,
            },
            totalPages: {
                type: Number,
                default: 1,
            },
            visiblePages: {
                type: Number,
                // default: 100,
            },
        },
        data() {
            return {
                currentPageLocal: this.currentPage,
                pagination: {
                    enabled: true,
                    totalItems: this.data.length, // Set the total number of items
                    perPage: 100, // Number of items per page
                },
            };
        },
        computed: {
            totalPageCount() {
                return Math.ceil(this.data.length / this.pagination.perPage)
            }
        },
        watch: {
            data: {
                handler() {
                    this.pagination.totalItems = this.data.length;
                    this.currentPageLocal = this.currentPage;
                },
                deep: true,
            },
            currentPageLocal() {
                this.$emit('update:currentPage', this.currentPageLocal);
            },
            totalPages() {
                this.pagination.totalItems = this.totalPageCount;
                this.currentPageLocal = this.currentPage;
            },
        }
    };
</script>
const menuList = [
    // {
    //   path: "/dashboard",
    //   name: "dashboard",
    //   icon: "mdi-home",
    // },
    {
      name: "Main",
      icon: "mdi-alpha-m-box-outline",
      children: [
        {
          path: "/training",
          name: "Data Training",
          icon: "mdi-memory",
        },
        {
          path: "/prediction",
          name: "Onboard Data",
          icon: "mdi-image-check-outline",
          disable: false
        },
        {
          path: "/galery",
          name: "Galery",
          icon: "mdi-image",
          disable: false
        },
        // {
        //   path: "/report",
        //   name: "Report",
        //   icon: "mdi-file-chart-outline",
        //   disable: true
        // },
      ],
    },
    {
      name: "Master Data",
      icon: "mdi-file-cabinet",
      children: [
        // {
        //   path: "/species",
        //   name: "Fish Identity",
        //   icon: "mdi-file-document-edit-outline",
        // },
        {
          path: "/vessel",
          name: "Vessel",
          icon: "mdi-ferry",
        },
        {
          path: "/device",
          name: "Devices",
          icon: "mdi-devices",
        },
      ],
    },
    {
      name: "Report",
      icon: "mdi-file-chart",
      children: [
        {
          path: "/onboard/report",
          name: "Data Vessel",
          icon: "mdi-image-check-outline",
        },
        {
          path: "/report/fishing-ground",
          name: "Fishing Ground",
          icon: "mdi-map-marker",
        }
      ],
    },
    {
      name: "Configuration",
      icon: "mdi-cog",
      children: [
        {
          path: "/user",
          name: "Users",
          icon: "mdi-account",
        },
        {
          path: "/system",
          name: "System",
          icon: "mdi-poll",
          disable: true
        }
      ],
    },
    
    
  ];
  
  export default menuList;
  